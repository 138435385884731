import axios from "axios";

const API_URL = "https://penacamargua.fr:8000"; // https://penacamargua.fr:8000

const pictureApi = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

export const login = async (password) => {
    const response = await api.post("/login", {
        password
    })
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error login : ", error);
        throw error;
    }
};

export const getPictures = async () => {
    const response = await api.get("/picture");
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching pictures : ", error);
        throw error;
    }
};

export const updatePictures = async (token, formData) => {
    const response = await pictureApi.post("/picture", formData, {
        headers: { Authorization: `Bearer ${token}` }
    });
    try {
        if (response.status === 201) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating pictures : ", error);
        throw error;
    }
};

export const deletePictures = async (token, name) => {
    const response = await api.delete(`/picture/${name}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error removing pictures : ", error);
        throw error;
    }
};

export const getShopElements = async () => {
    const response = await api.get("/shop");
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching shop elements : ", error);
        throw error;
    }
};

export const addShopElements = async (token, formData) => {
    const response = await pictureApi.post("/shop", formData, {
        headers: { Authorization: `Bearer ${token}` }
    });
    try {
        if (response.status === 201) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error adding shop element : ", error);
        throw error;
    }
};

export const removeShopElement = async (name, token) => {
    const response = await api.delete(`/shop/${name}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
    } catch (error) {
        console.error("Error removing a shop element : ", error);
        throw error;
    }
};

export const getDescription = async () => {
    const response = await api.get("/description");
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching description : ", error);
        throw error;
    }
};

export const updateDescription = async (token, text) => {
    const response = await api.post("/description", {
        content: text
    }, { headers: { Authorization: `Bearer ${token}` } });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating description : ", error);
        throw error;
    }
};

export const getVideos = async () => {
    const response = await api.get("/video");
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching description : ", error);
        throw error;
    }
};

export const updateVideos = async (videos, token) => {
    const response = await api.post("/video", {
        data: videos
    }, { headers: { Authorization: `Bearer ${token}` } });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating videos : ", error);
        throw error;
    }
};

export const updatePassword = async (token, newPassword) => {
    const response = await api.post("/password", {
        password: newPassword
    }, { headers: { Authorization: `Bearer ${token}` }});
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating password : ", error);
        throw error;
    }
};

export const getLegalMentions = async () => {
    const response = await api.get("/legal")
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching legal mentions : ", error);
        throw error;
    }
};

export const updateLegalMentions = async (token, text) => {
    const response = await api.post("/legal", {
        content: text
    }, {
        headers: { Authorization: `Bearer ${token}` }
    });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating legal mentions : ", error);
        throw error;
    }
};

export const getCgu = async () => {
    const response = await api.get("/cgu");
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching CGU : ", error);
        throw error;
    }
};

export const updateCgu = async (token, text) => {
    const response = await api.post("/cgu", {
        content: text
    }, { headers: { Authorization: `Bearer ${token}` } });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating CGU : ", error);
        throw error;
    }
};

export const getCgv = async () => {
    const response = await api.get("/cgv");
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error fetching CGV : ", error);
        throw error;
    }
};

export const updateCgv = async (token, text) => {
    const response = await api.post("/cgv", {
        content: text
    }, { headers: { Authorization: `Bearer ${token}` } });
    try {
        if (response.status === 200) {
            const result = response.data;
            return result;
        }
        return null;
    } catch (error) {
        console.error("Error updating CGV : ", error);
        throw error;
    }
}