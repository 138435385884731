import React from 'react';
import Homebar from '../components/Homebar';
import VideoPlayer from '../components/YoutubeVideo';
import InformationsModal from '../components/InformationsModal';
import { getDescription, getPictures, getVideos, getCgu, getCgv, getLegalMentions, deletePictures } from '../routes/requests';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import AddImageModal from '../components/AddImageModal';

const Home = () => {
    const [cgv, setCgv] = React.useState("");
    const [cgu, setCgu] = React.useState("");
    const [legal, setLegal] = React.useState("");
    const [showModal, setShowModal] = React.useState("");

    const [description, setDescription] = React.useState("");
    
    const [pictures, setPictures] = React.useState([]);
    const [backgroundPicture, setBackgroundPicture] = React.useState("");

    const [videos, setVideos] = React.useState([]);

    const [hasCookie, setHasCookie] = React.useState(false);

    const navigate = useNavigate();

    const changePage = (page) => {
        navigate(page);
    };

    const toggleModal = () => {
        setShowModal("");
    };

    const openPictureAdd = () => {
        setShowModal("picture");
    };

    const openCgv = () => {
        setShowModal("cgv");
    }

    const openCgu = () => {
        setShowModal("cgu");
    }

    const openLegal = () => {
        setShowModal("legal");
    }

    const removePicture = (name) => {
        deletePictures(Cookies.get("login"), name).then((result) => {
            if (result) {
                window.location.reload();
            }
        })
    };

    React.useEffect(() => {
        const getDescriptionApi = async () => {
            const result = await getDescription();
            setDescription(result.data);
        };

        const getPicturesApi = async () => {
            const result = await getPictures();
            setBackgroundPicture(result.filter((elem) => elem.location === "accueil").at(0).file);
            setPictures([...result.filter((elem) => elem.location !== "accueil" && elem.location !== "shop")]);
        };

        const getVideosApi = async () => {
            const result = await getVideos();
            setVideos([...result]);
        };

        const getCguCgvLegal = async () => {
            let result = await getCgu();
            setCgu(result.content);
            result = await getCgv();
            setCgv(result.content);
            result = await getLegalMentions();
            setLegal(result.content);
        }

        getDescriptionApi();
        getPicturesApi();
        getVideosApi();
        getCguCgvLegal();

        if (Cookies.get("login")) {
            setHasCookie(true);
        }
    // eslint-disable-next-line
    }, []);

    return (
        <div className='bg-black'>
            <Homebar />

            {/* Contenu de la page */}
            <div className="flex flex-col items-center justify-center">

                {/* Image accueil */}
                <div className='w-full bg-cover bg-center h-screen flex flex-col items-center justify-between' style={{
                    backgroundImage: `url("https://penacamargua.fr:8000/static/${backgroundPicture}")`,
                    height: 'calc(100vh - 56px)'
                }}>
                    <div>
                        <h1 
                            className="text-6xl font-bold text-center mb-8 text-mainBlue text-stroke"
                        >
                            Peña Camargua
                        </h1>
                    </div>
                    <div>
                        <button 
                            className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 hover:bg-secondBlue'
                            onClick={() => changePage("/boutique")}
                        >
                            Acheter notre album
                        </button>
                    </div>
                </div>

                {/* Qui sommes nous ? */}
                <div className='w-full h-auto pb-tenPercent'>
                    <h1 className='text-4xl font-bold text-center mt-4 text-white'>Qui sommes nous ?</h1>
                    <pre className='text-center text-white mt-8 mx-auto px-[5%] whitespace-pre-wrap break-words'>
                        { description }
                    </pre>
                </div>

                {/* Photos et vidéos */}
                <div className='w-full h-auto pb-tenPercent'>
                    <h1 className='text-4xl font-bold text-center mt-3 mb-3 text-white'>Nous découvrir en photo</h1>
                    { 
                        pictures.map((elem, index) => {
                            return <div
                                className='px-[5%] my-4'
                            >
                                <img src={"https://penacamargua.fr:8000/static/" + elem.file} alt="Pena Camargua" key={index}
                                    className='max-w-full h-auto'
                                />
                                {Cookies.get("login") ? 
                                    <button 
                                        key={index}
                                        className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 hover:bg-secondBlue'
                                        onClick={() => {
                                            removePicture(elem.file);
                                        }}
                                    >
                                        Supprimer l'image
                                    </button> : <></>
                                }
                            </div>;
                        })
                    }
                    <div className='mt-16'>
                        <h1 className='text-4xl font-bold text-center mb-3 text-white'>Nous découvrir en vidéo</h1>
                        {
                            videos.map((id, index) => {
                                return <VideoPlayer
                                    key={index}
                                    id_str={id}
                                />
                            })
                        }
                    </div>
                    {showModal === "cgv" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={cgv}
                            title={"Conditions générales de vente"}
                        />
                    )}
                    {showModal === "cgu" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={cgu}
                            title={"Conditions générales d'utilisation"}
                        />
                    )}
                    {showModal === "legal" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={legal}
                            title={"Mentions légales"}
                        />
                    )}
                    {showModal === "picture" && (
                        <AddImageModal 
                            onClose={toggleModal}
                        />
                    )

                    }
                </div>
                {
                hasCookie ?
                <div className="flex justify-center">
                    <button 
                    className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-1"
                    onClick={openPictureAdd}
                    >
                    +
                    </button>
                </div> : <></>
            }
                <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-5 hover:bg-secondBlue'
                onClick={openLegal}
            >
                Mentions légales
            </button>
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 hover:bg-secondBlue'
                onClick={openCgu}
            >
                Conditions Générales d'Utilisation
            </button>
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 hover:bg-secondBlue'
                onClick={openCgv}
            >
                Conditions Générales de Vente
            </button>
            </div>
        </div>
    );
};

export default Home;