import React from "react";
import Homebar from "../components/Homebar";
import PurchaseItem from "../components/PurchaseItem";
import InformationsModal from "../components/InformationsModal";
import { getShopElements, removeShopElement, deletePictures, getCgu, getCgv, getLegalMentions } from "../routes/requests";
import Cookies from "js-cookie";
import ModalForm from "../components/ModalForm";

const Boutique = () => {

    const [buyElements, setBuyElements] = React.useState([]);
    const [hasCookie, setHasCookie] = React.useState(null);
    const [showModal, setShowModal] = React.useState("");

    const [cgv, setCgv] = React.useState("");
    const [cgu, setCgu] = React.useState("");
    const [legal, setLegal] = React.useState("");
    
    React.useEffect(() => {
        const jwtCookie = Cookies.get("login");

        const getCguCgvLegal = async () => {
            let result = await getCgu();
            setCgu(result.content);
            result = await getCgv();
            setCgv(result.content);
            result = await getLegalMentions();
            setLegal(result.content);
        }

        if (jwtCookie) {
            setHasCookie(true);
        } else {
            setHasCookie(false);
        }
        getCguCgvLegal();
    }, [hasCookie]);

    const openCgv = () => {
        setShowModal("cgv");
    }

    const openCgu = () => {
        setShowModal("cgu");
    }

    const openLegal = () => {
        setShowModal("legal");
    }

    const groupByN = (array, groupSize) => {
        const grouppedArray = [[]];
        
        let n = 0;
        let array_num = 0;
        for (let i = 0; i < array.length; i += 1) {
            if (n === groupSize) {
                n = 0;
                array_num++;
                grouppedArray.push([]);
            }

            grouppedArray[array_num].push(array[i]);
            n++;
        }
        return grouppedArray;
    };

    const deleteElement = (element) => {
        const index = element % 3;
        const theList = Math.floor(element / 3);
        const jwtCookie = Cookies.get("login");

        deletePictures(jwtCookie, buyElements[theList][index].image);
        removeShopElement(buyElements[theList][index].name, jwtCookie).then((data) => {
            try {
                getShopElements().then(async (result) => {
                    await setBuyElements([...groupByN(result, 3)]);
                    return;
                });
            } catch (error) {
                console.error(error);
            }
        });
    };

    const openAddElement = () => {
        setShowModal("add");
    }

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    React.useEffect(() => {
        const fetchPicturesFromApi = async () => {
            try {
                const result = await getShopElements();
                await setBuyElements([...groupByN(result, 3)]);
                return;
            } catch (error) {
                console.error(error);
            }
        };

        fetchPicturesFromApi();
    }, []);

    return (
        <div className="bg-black min-h-screen">
            <Homebar />
            <div>
                <h1 
                    className="text-6xl font-bold text-center mb-8 mt-2 text-secondBlue text-stroke"
                >
                    Boutique
                </h1>
            </div>
            { buyElements.map((group, groupIndex) => {
                return <div key={groupIndex} className="flex justify-center flex-wrap">
                    { 
                        group.map((item, index) => {
                            return <PurchaseItem 
                                linkButton={item.link}
                                linkImage={"/static/" + item.image}
                                price={item.price}
                                productName={item.name}
                                key={index}
                                hasCookies={hasCookie}
                                deleteElement={deleteElement}
                                number={groupIndex * 3 + index}
                            />;
                        })
                    }
                </div>;
            }) }
            {
                hasCookie ?
                <div className="flex justify-center">
                    <button 
                    className="bg-mainBlue text-white font-bold px-8 py-3 rounded-full mt-8"
                    onClick={openAddElement}
                    >
                    +
                    </button>
                </div> : <></>
            }
            {showModal === "add" && (
                <ModalForm onClose={toggleModal} />
            )}

            {showModal === "cgv" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={cgv}
                            title={"Conditions générales de vente"}
                        />
                    )}
                    {showModal === "cgu" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={cgu}
                            title={"Conditions générales d'utilisation"}
                        />
                    )}
                    {showModal === "legal" && (
                        <InformationsModal 
                            onClose={toggleModal}
                            description={legal}
                            title={"Mentions légales"}
                        />
                    )}
            
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-16 hover:bg-secondBlue'
                onClick={openLegal}
            >
                Mentions légales
            </button>
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 ml-5 hover:bg-secondBlue'
                onClick={openCgu}
            >
                Conditions Générales d'Utilisation
            </button>
            <button 
                className='bg-mainBlue text-gray-50 text-stroke px-4 py-2 rounded mb-5 mt-1 ml-5 hover:bg-secondBlue'
                onClick={openCgv}
            >
                Conditions Générales de Vente
            </button>

        </div>
    );
};
export default Boutique;