import React from "react";
import { updatePictures } from "../routes/requests";
import Cookies from "js-cookie";

const AddImageModal = ({
    onClose
}) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const sendFormData = new FormData();
        const image = formData.get("image_picture");
        if (image.type.startsWith("image/")) {
            sendFormData.append("image", image);
            sendFormData.append("type", "home");
            updatePictures(Cookies.get("login"), sendFormData).then((result) => {
                if (result) {
                    onClose();
                    window.location.reload();
                }
            });
        }
    };

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="bg-darkGray rounded-lg p-8">
                <h2 className="text-2xl font-bold mb-4 text-white">Ajouter une nouvelle image</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Image</label>
                        <input type="file" id="image_picture" name='image_picture'
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            placeholder=""
                            required
                        />
                    </div>
                    <div className="flex justify-end mt-4">
                        <button type="submit" className="bg-mainBlue text-white px-4 py-2 rounded-md font-bold">Ajouter une image</button>
                        <button type="button" onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-md font-bold ml-2">Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddImageModal;
